import customer from './consumer';

customer.subscriptions.create({ channel: 'EmpresarialReportChannel' }, {
  received({company_id}) {
    if (window.location.pathname === `/clientes/${company_id}`){
      $.ajax({
        type: "GET",
        url: `${window.location.origin}/companies/${company_id}/external_reports/empresarial_report_status`,
        dataType: "script"
      })
    } else if (window.location.pathname === `/companies/${company_id}/external_reports/empresarial_report`){
      $.ajax({
        type: "GET",
        url: `${window.location.origin}/companies/${company_id}/external_reports/empresarial_report`,
        dataType: "script"
      })
    }
  }
})