// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
global.$ = require("jquery");
global.$alerts = require("packs/utils/alerts");
global.$modals = require("packs/utils/modals");
import createRoom from "packs/subscriptions/chat_rooms/createRoom";
window.createRoom = createRoom
require('@babel/polyfill');

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("packs/base");
require("packs/layout");

// Views
require("packs/dashboard")
require("packs/companies")
require("packs/sessions")
require("packs/partners")
require("packs/archives")
require("packs/banks")
require("packs/deals")
require("packs/documents")
require("packs/validates")
require("packs/comments")
require("packs/notifications")
require("packs/users")
require("packs/administrations")
require("packs/legal_representatives")
require("packs/portfolio")
require("packs/excess")
require("packs/tools/power_judicials")
require("packs/crm")
require("packs/tools/send_document")
require("packs/tablesortable")
require("packs/mask")
require("packs/transferred_invoice_status")
require("packs/daily_client_transferred_invoice")
require("packs/big_payers")
require("packs/daily_transferred_invoice")
require("packs/confirmations")
require("packs/lines")
require("packs/sessions/client")
require("packs/clients/deals")
require("packs/clients/archive")
require("packs/clients/documents")
require("packs/clients/dashboard")
require("packs/clients/debtors")
require("packs/clients/excesses")
require("packs/clients/operations")
require("packs/sii_credentials")
require("packs/lines")
require("packs/operation")
require("packs/tools/mobile_combobox")
require("packs/purchase_orders")
require("packs/custom_masks")

global.$ = require("jquery");

require("bootstrap");
require("flatpickr");
require("flatpickr/dist/l10n/es.js").default.es;
require("packs/widgets/awesomplete")
require("packs/confirmation_protocol.js")

import "moment/moment.js";
import "xlsx/xlsx.js";
import "select2";
import "chartkick/chart.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on("turbolinks:load", common_events);

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}


function common_events(){
  $('#icon-whatsapp-link').hover(function(){
      $('.text-icon-whatsapp').fadeIn(200)
  }, function () {
      $('.text-icon-whatsapp').fadeOut(200)
  })

  $('.trix-button-group--file-tools').remove()

  $('#user_terms_and_conditions').change(function(){
      $(this).val(this.checked);
  });


  $('body').on('input', '.rut-input', function (event){
    $(this).attr('maxlength', 12)
    const value = event.currentTarget.value
    const cleanValue = value.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()

    const dv = cleanValue.slice(-1);
    const rut = cleanValue.slice(0,-1)
    const chunkedRut = sliceIntoChunks(rut.split('').reverse(), 3)

    const formatedRut = chunkedRut.reverse().map(numbers => numbers.reverse().join('')).join('.')

    if (dv) {
      $(this).val(`${ formatedRut }-${ dv }`)
    } else {
      $(this).val('')
    }
  });

  $(".datetimepicker-month").flatpickr({
      dateFormat: "d-m-Y",
      mode: "range",
      maxDate:"today",
      maxDays: 31,
      locale: "es"
  });

  function changeOffset(){
    let footer = $('#footer-session');
    if (window.screen.width > 768){
        footer.addClass('offset-5');
    } else{
        footer.removeClass('offset-5');
    };
  }

  changeOffset();

  function loader(e) {
    $(".loader").fadeOut(1000);
    $("#body").removeClass("hidden-loader");
  }

  $("window").on("load", loader());

  $("#current-company-select").on("change", function () {
    $.ajax({
      url: $(this).data("url"),
      dataType: "script",
      method: "POST",
      data: { company_id: $(this).val() },
    });
  });

  $("body").on("click", ".simple-load", function (e) {
    let url = $(this).data("url");
    $.ajax({
      type: "GET",
      url: url,
      dataType: "script",
    });
  });

  $("body").on("click", () => {
    if ("Notification" in window) {
      if (Notification.permission !== "denied") {
        Notification.requestPermission();
      }
    }
  });

  $('window').on('load', loader());

  $('#current-company-select').on("change", function (){
      $.ajax({
          url: $(this).data("url"),
          dataType: 'script',
          method: "POST",
          data: {company_id: $(this).val()}
      });
  });

  $('body').on('click', '.load-data-url', function (e) {
    const urlArray = $(this).data('url').split(',')
    const method = $(this).data('method')

    if (!$(this).data('no-loader')) {
      $('.loader').fadeIn(100)
    }

    urlArray.forEach(url => {
      $.ajax({
        type: method || "GET",
        url: url,
        dataType: "script",
        success: function () {
          $('.loader').fadeOut(500);
        },
        error: function (){
          $('.loader').fadeOut(500);
          console.warn("Hubo un error inesperado!");
        }
      });
    })
  });

  // Click the tab button that comes in the url 
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const idButton = urlParams.get('go_to')
  $(`#${idButton}`).trigger('click')

  $('body').on('click', '.open-window-url', function() {
    const urlArray = $(this).data('window-url').split(',')
    urlArray.forEach(url => window.open(url, '_blank'))
  })


  $('body').on('click', '.simple-load', function (e) {
    let url = $(this).data('url');
    $.ajax({
      type: "GET",
      url: url,
      dataType: "script"
    });
  });

  $('body').on('click', '.loader-click',() => {
      $('.loader').fadeIn()
  })

  $('body').on('click', '.dropdown-list.show', function(event) {
      event.stopPropagation()
  })
}



function on_selected_item_change(args){
    alert(args);
}


function pdf(){

    const doc = new jsPDF("p","px", "letter", true);

    /*var svgElements = document.body.querySelectorAll('svg');
    svgElements.forEach(function(item) {
        item.setAttribute("width", item.getBoundingClientRect().width);
        item.setAttribute("height", item.getBoundingClientRect().height);
        item.style.width = null;
        item.style.height= null;
    });*/

    let pdfData = $('.report-html').html()

    doc.html(pdfData, {
        callback: (pdf) => {
            window.open(pdf.output('bloburl'))
        },
        html2canvas: { scale: 0.35 }
        // optional: page margin
        // optional: other HTMLOptions
    })
}

global.initFlatpickrElement = ($element) => {
  flatpickr.localize(flatpickr.l10ns.es);
  const plugin = $element.data('plugin')
  const plugins = []
  if (plugin === 'month') {
    plugins.push(
      new monthSelectPlugin({
        shorthand: true,
        dateFormat: "F, Y",
        altFormat: "F, Y",
      })
    )
  }
  $element.flatpickr({
    dateFormat: "d-m-Y",
    disableMobile: $element.data('disable-mobile') || null,
    mode: $element.data('mode') || 'single',
    defaultDate: $element.data('default')?.split(',') || null,
    minDate: $element.data('min-date') || null,
    onOpen: [
      function(selectedDates, dateStr, instance){
          // Reset datetimepicker range form trigger
          var formId = $(this)[0]["element"]["dataset"]["formId"]
          $(formId).attr("data-calendar-datetimepicker-r", "no") 
          const $datePicker = $(instance.calendarContainer)
          const $input = $(instance.element)
          if ($input.data('container')) {
            $datePicker.attr('id', $input.data('date-picker-id'))
            $($input.data('container')).html($datePicker)
          }
      }
    ],
    onChange: function(selectedDates, dateStr, instance) {
      if (selectedDates.length >= 2) {
        const formId = $(this)[0]["element"]["dataset"]["formId"];
        $(formId).attr("data-calendar-datetimepicker-r", "yes")
      }
    },
    plugins
  })
}

require("trix")
require("@rails/actiontext")
